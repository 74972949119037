import React from 'react';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core';
import { Container, useMediaQuery } from '@material-ui/core';

import { Intro } from './Intro';
// import { FeatureLinks } from './FeatureLinks';
import { CtaCard } from '../../General/CtaCard';

const FeatureLinks = loadable(() => import('./FeatureLinks'));

const useStyles = makeStyles((theme) => ({
  introCont: {
    paddingBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '2rem',
    },
  },
}));

export const HomeBody = ({
	intro,
	homeLinks,
	homeLinksHeader,
	homeLinksCallout,
	homeCta,
}) => {
  const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');
	const {
		ctaHeader,
		ctaSubheader,
		ctaText,
		ctaLink,
		internalLink,
		ctaBackground,
	} = homeCta;

	return (
		<>
			<div
				style={{
					width: '100%',
					boxShadow: '0 40px 40px -40px rgba(0,0,0,0.10)',
					position: 'relative',
				}}>
				<Container className={classes.introCont}>
					<Intro intro={intro} />
				</Container>
			</div>
			<FeatureLinks
				homeLinksHeader={homeLinksHeader}
				homeLinks={homeLinks}
				homeLinksCallout={homeLinksCallout}
			/>
			<Container>
				<CtaCard
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					ctaLink={ctaLink}
					internalLink={internalLink}
					background={ctaBackground}
				/>
			</Container>
		</>
	);
};
